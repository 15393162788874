<template>
  <div class="plk_pagecode">
    <Menu></Menu>
    <div class="javascript">
      <h5>
        <span v-if="!showDocumentation">{{id}}</span>
        <a v-if="!showDocumentation" @click="showDocumentation=true" class="pull-right pointer" :title="$t('designer.design.showDoc')"><i class="fas fa-book colored"></i></a>
        <a v-if="showDocumentation" @click="showDocumentation=false" class="pull-right pointer" :title="$t('designer.design.showCode')"><i class="fas fa-code colored"></i></a>
        <a class="pull-right mx-3" :href="id" :title="$t('designer.design.showNewPage')" target="_blank"><i class="fas fa-eye colored"></i></a>
      </h5>
      <codemirror v-if="!showDocumentation" v-model="code" :options="cmOptions" />
      <div v-if="showDocumentation" class="plk_documentation" v-html="readme"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import i18n from './i18n'
import { codemirror } from 'vue-codemirror'
import Menu from './components/menus/Menu'
import 'codemirror/lib/codemirror.js'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/elegant.css'
import readme from '@/README.md'
import { Store, DESIGN } from '@/store'

export default {
  name: 'pagecode',
  components: {
    codemirror,
    Menu
  },
  data () {
    return {
      id: this.$route.query.id || 'index',
      code: '',
      showDocumentation: false,
      updatedView: false,
      readme,
      cmOptions: {
        tabSize: 2,
        autoCloseTags: true,
        mode: 'text/javascript',
        theme: 'elegant',
        lineNumbers: true,
        styleActiveLine: true,
        line: true,
        matchBrackets: true
      }
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.loadView()
    },
    async loadView () {
      const view = await this.$servicesPLK.component.getView(this.id)
      Vue.set(this, 'updatedView', view !== undefined)
      Vue.set(this, 'code', JSON.stringify((view || {}), null, 2))
    }
  },
  watch: {
    code () {
      try {
        const newView = JSON.parse(this.code)
        Store.commit({
          type: this.updatedView ? DESIGN.MUTATIONS.UPDATE_VIEW : DESIGN.MUTATIONS.CREATE_VIEW,
          view: this.id,
          metaInfo: newView.metaInfo,
          actions: newView.actions,
          viewClass: newView.viewClass,
          components: newView.components
        })
      } catch (e) {
      }
    }
  }
}
</script>
<style lang="scss">
  .plk_pagecode {
    .CodeMirror {
      border: 1px solid #dee2e6;
      height: calc(93vh - 5.5rem);
    }
  }
</style>
<style scoped lang="scss">
  .plk_pagecode {
    background: #f8f9fa;

    .javascript {
      padding: 3vh;
      padding-left: calc(3vh + 50px);
    }
    .menu_components {
      padding: 5px;
      background: #848e98;
      height: 100vh;
      overflow: scroll;
      border-right: solid 1px #dee2e6;
    }
  }
</style>
